<template>
    <section :class="setClassProduct" mode="out-in">
        <ProductSelect :product="product" :fotos="fotos" :color="color"/>
        <div class="grid-images">
          <DinamicGridImages :fotos="getObrasByProduct"/>
        </div>
    </section>
</template>

<script>
import ProductSelect from './ProductSelect'
import ObrasService from '../services/ObrasService'
import DinamicGridImages from '../components/DinamicGrid/Images'
import ProductosService from '../services/ProductosService'
import FamiliasProductosService from '../services/FamiliasProductosService'
export default {
  name: 'product page',
  components: {
    DinamicGridImages,
    ProductSelect
  },
  data () {
    return {
      id: this.$route.params.product.split('-')[0],
      title: this.$route.params.product.split('-')[1],
      color: this.$route.query.color,
      fotos: [],
      product: this.$route.query,
      obras: [],
      products: []
    }
  },
  computed: {
    setClassProduct () {
      return this.productSelected ? 'products-full-height' : 'products-page'
    },
    setClassGrid () {
      return this.productSelected ? 'grid-images' : ''
    },
    getObrasByProduct () {
      return this.obras.filter(obra => obra.productos.some(producto => producto.nombre === this.product.name))
    }
  },
  methods: {
    getProductById () {
      const serviceProduct = new ProductosService()
      serviceProduct.findById({ id: this.id }, response => {
        this.fotos = response.fotos
      })
      // this.product = this.fotos.find(id => this.id === id)
    },
    getObras () {
      const service = new ObrasService()
      service.findAll({}, (response) => {
        this.obras = response
      })
    },
    getProductos () {
      const service = new FamiliasProductosService()
      service.findById({ }, (response) => {
        this.products = FamiliasProductosService.parse(response)
      })
    }
    // selectProduct (product) {
    //   this.product = product
    //   this.productSelected = true
    // }
  },
  mounted () {
    this.getProductById()
    this.getObras()
    this.getProductos()
  }
}
</script>

<style lang="scss" scoped>
    .products-page {
        position: relative;
        background: #1A1A1A;
        padding-block: 6rem 1rem;
    }
    .grid-images{
        padding-inline: 40px;
    }
    .products-full-height{
        margin: auto;
        min-height: 100vh;
        padding-block-start: 8rem;
        background: #1A1A1A;
    }
</style>
