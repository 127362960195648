<template>
    <div class="material-popup-container">
      <div class="d-flex flex-column position-relative material-popup-info-container justify-content-center" :style="{backgroundImage: 'url(' + product.foto + ')'}">
        <span :style="{background: color}">.Pdf</span>
      </div>
    </div>
  </template>
<script>
import { isMobile } from 'mobile-device-detect'
export default {
  name: 'ProductSelect',
  props: ['product', 'color'],
  components: {
  },
  data () {
    return {
      isMobile,
      mainStyles: {
        backgroundImage: `url(${require('@/assets/img/material-background.jpg')})`
      }
    }
  },
  methods: {},
  mounted () {
    window.scrollTo({ top: 0 })
    this.mainStyles.backgroundImage = this.product.foto
  }
}
</script>

<style scoped lang="scss">
  .material-popup-container {
    position: relative;
    background-color: #000000;
    transition: all 0.5s ease-in-out;
    margin-bottom: 2rem;
    margin-top: -9rem;
    .material-popup-info-container {
      background-color: #000000;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;
      -webkit-animation: backgroundFadeIn 5s ;
      animation: backgroundFadeIn 5s ;
    }
  }
  span{
      width: 4%;
      display: block;
      padding-block: 0.8rem;
      text-align: center;
      position: absolute;
      bottom: 1.5rem;
      left: 2.5rem;
      font-size: 1.25rem;
      font-family: 'IBMPlexSans-SemiBold', sans-serif !important;
  }
  @media (max-width:1200px){
    span{
      width: 8%;
    }
  }
  @include media-breakpoint-down(lg) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: calc(var(--main-space) / 2);
            font-size: 48px;
          }
          p {
            font-size: 24px;
            line-height: 150%;
            width: 100%;
            margin: calc(var(--main-space) / 2) 0;
          }
          .button-obras {
            span {
              padding: calc(var(--main-space) / 5 * 2);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: var(--main-space);
          }
          p {
            width: 100%;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .material-popup-container {
      .material-popup-info-container {
        min-height: 100vh;
        height: auto;
        .wrapper {
          h2 {
            font-size: 36px;
            margin-top: 0;
          }
          p {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
</style>
