<template>
    <section class="work">
      <div class="work-items">
        <FadeUp
          :src="obra.fotoFile"
          :src-placeholder="require('@/assets/img/default-img.jpg')"
          @load="imageLoaded()"/>
      </div>
      <h1 class="bcLiguria">{{ obra.nombre }}</h1>
      <article class="work-article">
        <div class="work-article-item">
            <ul>
              <li>
                <h3 class="work-article-item-title-first">Ubicación</h3>
                <p class="work-article-item-first">{{ obra.ubicacion }}</p>
              </li>
              <li>
                <h3 class="work-article-item-title-first">Estudio de arquitectura</h3>
                <p class="work-article-item-first">{{ obra.estudio }}</p>
              </li>
            </ul>
        </div>
        <div class="work-article-item">
          <ul>
            <li class="work-article-item-li">
              <h3 class="work-article-item-title">Producto</h3>
              <div>
                <p class="bcLiguria" :style="{backgroundColor: $route.query.color}" v-for="productos in obra.productos" :key="productos.id">{{ productos.nombre }}</p>
              </div>
            </li>
            <li class="work-article-item-li">
              <h3 class="work-article-item-title">Aplicación</h3>
              <div v-if="obra.aplicaciones">
                <p class="bcLiguria" v-for="aplicaciones in obra.aplicaciones" :key="aplicaciones.id">{{ aplicaciones.nombre }}</p>
              </div>
              <div v-else>
                <p>No tiene aplicación</p>
              </div>
            </li>
            <li class="work-article-item-li">
              <h3 class="work-article-item-title">Materialidad</h3>
              <div v-if="obra?.materiales">
                <p class="bcLiguria" v-for="materiales in obra.materiales" :key="materiales.id">{{ materiales.nombre }}</p>
              </div>
              <div v-else>
                <p>No tiene materiales</p>
              </div>
            </li>
          </ul>
        </div>
      </article>
      <div class="work-fotos">
        <GridWork v-for="foto in obra.fotos" :key="foto" :foto="foto" />
      </div>
    </section>
</template>
<script>
import ObrasService from '../services/ObrasService'
import { EventBus } from '@/event-bus'
import GridWork from '@/components/DinamicGrid/Work.vue'
import FadeUp from '@/components/lazy-load-images/FadeImage'
export default {
  name: 'work',
  components: {
    GridWork,
    FadeUp
  },
  data () {
    return {
      obra: [],
      fotos: [],
      showLoading: true
    }
  },
  methods: {
    getObras () {
      const service = new ObrasService()
      const id = this.$route.params.work.split('-')[0]
      service.findAll({ id }, (response) => {
        this.obra = response
      })
    },
    getFotos () {
      this.obra.forEach(obra => {
        obra.fotos.forEach(foto => {
          this.fotos.push(foto)
        })
      })
    }
  },
  mounted () {
    this.getObras()
  },
  watch: {
    $route: {
      handler () {
        EventBus.$emit('showTitlePage', this.$route.query.name)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .work{
    position: relative;
    background: #1A1A1A;
    color: white;
    margin-top: calc(var(--main-space) * 4);
    gap: 1.5em;
    h1{
      font-size: 3em;
      padding-inline: 40px;
    }
    &-items{
      width: 100%;
      margin: auto;
      position: relative;
      img{
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: center;
        border: none !important;
        margin-block-end: 80px;
      }
    }
    &-article{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-block: 80px;
      gap: 2rem;
      padding-inline: 40px;
    }

    .work-article-item-li{
      div{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        p{
          padding:1rem;
          background-color: #000000;
          //font-family: 'IBM Plex Sans'
        }
      }
    }
    .work-article-item-title{
        margin-block-end: 1rem;
    }
    .work-article-item-title-first{
        font-size: 1rem;
    }
    .work-article-item-first{
        font-size: 1.75rem;
    }
    &-fotos{
      width: 100%;
      padding-inline: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }
  @media (max-width: 768px){
    .work-article{
      display: grid;
      grid-template-columns: 1fr;
      place-content: center;
      gap: 2rem;
    }
    .work-fotos{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
</style>
