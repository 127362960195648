<template>
  <footer class="footer d-flex flex-column justify-content-lg-center justify-content-evenly" :class="{'menu-open': !showMenuIcon}" :style="{'z-index': numberPosition}">
      <!-- <div class="footer-brand-power d-flex flex-md-row flex-column justify-content-between align-items-center">
        <span class="email-footer">hola@grupomsh.com</span>
      </div> -->
      <div class="logo-footer">
        <SVGLogo />
      </div>
  </footer>
</template>

<script>
import SVGLogo from '@/assets/img/logo-footer.svg'
import Helper from '@/helpers/Helper'
import { isMobile } from 'mobile-device-detect'
import { EventBus } from '@/event-bus'
export default {
  components: {
    SVGLogo
  },
  name: 'Footer',
  data () {
    return {
      showMenu: true,
      scrollPos: 0,
      familyProducts: [],
      showLanguageIcon: true,
      showMenuIcon: true,
      showMoreIcon: false,
      showFamilyGridIcon: true,
      showFamilyIcons: true,
      showFundermaxIcon: false,
      showContactsIcons: false,
      familySelected: null,
      blackIcons: false,
      whiteIcons: false,
      iconsCompressed: false,
      showTitlePage: false,
      title: '',
      currentTitlePage: '',
      numberPosition: 100
    }
  },
  methods: {
    setTitlePage (title) {
      this.title = title
    },
    toggleContactsIcons (show) {
      this.showFamilyIcons = !show
      this.showContactsIcons = show
      this.showFundermaxIcon = !show
    },
    toggleGridFamilyDots () {
      this.showFamilyGridIcon = !this.showFamilyGridIcon
      this.toggleContactsIcons(!this.showFamilyGridIcon)
      this.numberPosition = 0

      if (!this.showFamilyGridIcon) {
        this.setTitlePage(this.$t('header.products'))
        this.toggleShowTitle(true)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }

      EventBus.$emit('bodyScroll', this.showFamilyGridIcon)
    },
    listenOnScroll () {
      let pos = this.scrollPos
      window.addEventListener('scroll', (e) => {
        pos = window.scrollY
        if (!Helper.isHome() && !Helper.isFundermax()) {
          // this.showMenu = (pos < this.scrollPos)
          this.showMenu = pos === 0
          this.scrollPos = pos
        } else {
          let el = document.getElementById('home-section-services')
          if (Helper.isFundermax()) {
            el = document.getElementById('fundermax-section-services')
          }
          if (el !== null) {
            // this.showMenu = (pos < this.scrollPos || pos < el.offsetHeight)
            this.showMenu = pos < (el.offsetHeight - 200) // DEJO 200PX PARA QUE NO SE ENCIME CON EL TITULO
            this.scrollPos = pos
          }
        }
      })
    },
    toggleMenu () {
      this.showMenuIcon = !this.showMenuIcon
      this.showMenu = !this.showMenuIcon || (window.scrollY === 0)
      this.toggleContactsIcons(!this.showMenuIcon)
      this.numberPosition = 0
      EventBus.$emit('bodyScroll', this.showMenuIcon)
      if (this.showMenuIcon && this.$route.name === 'GPTW') {
        this.showFamilyIcons = false
      }
      // if (this.$route.path.includes('works')) {
      //   this.whiteHeaderProp = !this.whiteHeaderProp
      // }

      if (!this.showMenuIcon) {
        this.toggleShowTitle(false)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }
    },
    toggleHoverMenu (toggle) {
      if (!isMobile) {
        this.showMoreIcon = toggle
      }
    }
  },
  mounted () {
    EventBus.$on('getFamilies', () => {
      return this.familyProducts
    })

    EventBus.$on('toggleMenuIcon', (toggle) => {
      this.showMenuIcon = toggle
    })
    EventBus.$on('toggleGridFamilyIcon', (toggle) => {
      this.showFamilyGridIcon = toggle
    })
    EventBus.$on('headerFamilySelected', (family) => {
      this.familySelected = family
      this.iconsCompressed = (family !== null)
    })
    EventBus.$on('toggleHeaderFamilyIcons', (toggle) => {
      this.showFamilyIcons = toggle
    })
    EventBus.$on('toggleHeaderBlack', (toggle) => {
      this.blackHeader = toggle
    })
    EventBus.$on('showTitlePage', (titlePage) => {
      this.currentTitlePage = titlePage
      this.setTitlePage(titlePage)
      this.toggleShowTitle(true)
    })
    EventBus.$on('showLogo', () => {
      this.currentTitlePage = ''
      this.setTitlePage('')
      this.toggleShowTitle(false)
    })
    EventBus.$on('showContactsIcons', (show) => {
      this.toggleContactsIcons(show)
    })
  },
  watch: {
    showMenuIcon: {
      handler () {
        EventBus.$emit('toggleMenu', !this.showMenuIcon)
        if (!this.showMenuIcon || this.$route.name === 'GPTW') {
          EventBus.$emit('toggleGridFamilyDots', false)
        }
      }
    },
    showFamilyGridIcon: {
      handler () {
        EventBus.$emit('toggleGridFamilyDots', !this.showFamilyGridIcon)
        if (!this.showFamilyGridIcon) {
          EventBus.$emit('toggleMenu', false)
          this.hideContactsIcons()
        }
      }
    }
  }
}
</script>

<style lang="scss">
    .header-icon {
        fill: #8C9091;
        path {
          fill: #8C9091;
        }
      }
  .footer {
    background-color: #1A1A1A;
    padding-inline: var(--main-space);
    width: 100%;
    /*height: var(--main-space);*/
    position: sticky;
    bottom: 0;
    min-height: 100vh;
      .logo-footer{
        margin-top: auto;
          svg{
            position: relative;
          }
          .span-footer{
            position: absolute;
            right: 0%;
            bottom: -24%;
            transform: rotate(90deg);
          }
        }
        .footer-brand {
          font-size: 1.2rem;
          color: $footer-text;
          display: block;
          margin-bottom: 18rem;
        }
      .footer-brand-power {
        row-gap: 2rem;
        margin-bottom: 14rem;
        .email-footer{
          font-size: 2rem;
          font-family: 'BC-Liguria', sans-serif;
          color: #8C9091;
          opacity: 0;
        }
        .footer-power-by {
          font-size: 0.8rem;
          color: $footer-text;
          a {
            font-size: 0.8rem;
            color: #8C9091;
          }
        }
      }
  }

  @include media-breakpoint-down(sm) {
    .footer {
      /*height: calc(var(--main-space) * 4);*/
      .footer-brand {
        width: 100%;
        .email-footer{
          font-size: 4rem;
        }
      }
      .footer-power-by {
        width: 100%;
        text-align: right;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .footer {
      .footer-power-by {
        margin-top: calc(var(--main-space)/2);
        text-align: left;
      }
    }
  }
  @media (max-width: 1200px){
    .footer{
      padding-block-end: 40px;
    }
  }
</style>
