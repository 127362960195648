import { render, staticRenderFns } from "./PartnerBox.vue?vue&type=template&id=f4cd7946&scoped=true"
import script from "./PartnerBox.vue?vue&type=script&lang=js"
export * from "./PartnerBox.vue?vue&type=script&lang=js"
import style0 from "./PartnerBox.vue?vue&type=style&index=0&id=f4cd7946&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4cd7946",
  null
  
)

export default component.exports