<template>
    <section class="family-product-container">
        <div class="family-product-container-items">
          <p>{{ familyProduct.text1 }}</p>
          <span :style="{background: familyProduct.color}">.PDF</span>
          <img :src="familyProduct.foto" :alt="familyProduct.text1" />
        </div>
        <div class="family-product-products">
          <DinamicGridImages :products="familyProduct"/>
        </div>
    </section>
</template>

<script>
import FamiliasProductosService from '../services/FamiliasProductosService'
import DinamicGridImages from '../components/DinamicGrid/Images'
import { EventBus } from '@/event-bus'
import { isMobile } from 'mobile-device-detect'
export default {
  components: {
    DinamicGridImages
  },
  data () {
    return {
      familyProduct: null
    }
  },
  methods: {
    getFamilyProduct (id) {
      id = id || this.$route.params.familyProduct.split('-')[0]
      const service = new FamiliasProductosService()
      service.findById({ id: id }, (response) => {
        this.familyProduct = FamiliasProductosService.parse(response)
        EventBus.$emit('headerFamilySelected', this.familyProduct)
        if (!isMobile) {
          this.updateBackgroundImage()
        }
      })
    }
  },
  mounted () {
    this.getFamilyProduct()
    EventBus.$emit('showLogo')
  },
  watch: {
    $route: {
      handler () {
        this.getFamilyProduct()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
    .family-product-container{
        position: relative;
        background: #1A1A1A;
        padding-block: 6rem 1rem;
        &-items{
          width: 100%;
          margin: auto;
          position: relative;
          img{
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            margin-block-end: 40px;
          }
          p{
            margin-inline-start: 12rem;
            width: 30%;
            color: #8C9091;
            margin-block-end: 40px;
          }
          span{
            width: 4%;
            display: block;
            margin-inline-start: 12rem;
            padding-block: 0.8rem;
            text-align: center;
            margin-block: 40px;
            font-size: 1.25rem;
            font-family: 'IBMPlexSans-SemiBold', sans-serif;
          }
        }
        .family-product-products{
          padding-inline: 40px;
        }
    }
    @media screen and (max-width:1200px){
      span{
        width: 10% !important;
      }
    }
</style>
