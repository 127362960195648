<template>
    <article class="values-article" :class="classArticle">
        <div class="values-article-name">
            <h3 class="bg-liguria text-white">{{ values.name }}</h3>
            <p class="bg-liguria text-white">{{ values.description }}</p>
        </div>
        <figure class="values-article-figure" :class="classValues">
            <img class="values-article-img" :src="backgroundImage" :alt="values.name"/>
        </figure>
    </article>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'AboutServices',
  props: ['values', 'id'],
  data () {
    return {
      backgroundImage: require(`@/${this.values.img}`)
    }
  },
  computed: {
    classValues () {
      return `img-number-${this.id}`
    },
    classArticle () {
      return `value-number-${this.id}`
    }
  },
  mounted () {
    // const bg = document.querySelector(`.value-number-${this.id}`)
    // const realHeight = bg.clientHeight - window.innerHeight
    const tl = gsap.timeline()
    // tl.from(`.value-number-${this.id} .img-number-${this.id}`, {
    //   duration: 6,
    //   width: '0',
    //   height: '0',
    //   minHeight: '0'
    // })
    tl.to(`.value-number-${this.id} .img-number-${this.id}`, {
      ease: 'expo.inOut',
      scrollTrigger: {
        trigger: `.value-number-${this.id}`,
        start: 'top top',
        end: 'bottom 0',
        pin: true,
        anticipatePin: 1,
        scrub: 1
      },
      width: '100%',
      minHeight: '100%',
      duration: 6
    })
  }
}
</script>

<style lang="scss" scoped>
    .values-article{
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-name{
        padding-block-start: 14vw;
        position: relative;
        background: #1A1A1A;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3{
          font-size: 148px;
          z-index: 1000;
          mix-blend-mode: difference;
          position: absolute;
          bottom: 45%;
        }
        p{
          max-width: 540px;
          margin: auto;
          font-size: 1.5rem;
          text-align: center;
          position: absolute;
          bottom: 40px;
        }
      }
      &-figure{
        background: #1A1A1A;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        // background-image: url('../assets/img/valores/compromiso.jpg');
      }
    }
    @media screen and (max-width:1280px) {
      .values-article{
        min-height: 100vh;
        background: #1A1A1A;
        &-name{
          // height: 50vh;
          h3{
              font-size: 120px;
          }
          p{
            max-width: 80%;
          }
        }
      }
    }
    @media screen and (max-width:768px){
      .values-article{
        &-name{
          h3{
              font-size: 80px;
          }
        }
      }
    }
    @media screen and (max-width:640px){
      .values-article{
        &-name{
          h3{
              font-size: 40px;
          }
          p{
            font-size: 20px;
          }
        }
      }
    }
</style>
