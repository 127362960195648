import { render, staticRenderFns } from "./Work.vue?vue&type=template&id=43c11775&scoped=true"
import script from "./Work.vue?vue&type=script&lang=js"
export * from "./Work.vue?vue&type=script&lang=js"
import style0 from "./Work.vue?vue&type=style&index=0&id=43c11775&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43c11775",
  null
  
)

export default component.exports