<template>
  <div class="service-box" :class="[classes, {'service-box-img': img}, `grid-item-${clasItem + 1}`]">
  <template v-if="clasItem == 1">
      <div class="dashed dashed-left"></div>
  </template>
  <template v-if="clasItem == 3">
      <div class="dashed dashed-right"></div>
  </template>
    <div class="service-box-info-container" :class="{'not-hover': notHover}">
      <figure v-if="img">
        <img :src="require('../' + img)" alt="" />
      </figure>
      <h4 class="text-white" :class="{'bcLiguria': description}">{{name.toUpperCase()}}</h4>
      <div class="arrow-container" v-if="linkTo">
        <ArrowIcon></ArrowIcon>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '../assets/img/arrow-icon.svg'
export default {
  name: 'HomeService',
  props: ['name', 'description', 'model', 'linkTo', 'classes', 'img', 'notHover', 'clasItem'],
  components: {
    ArrowIcon
  },
  data () {
    return {
      showFullDescription: false
    }
  }
}
</script>

<style scoped lang="scss">
  //.grid-item-1{
  //    grid-column-start: 1;
  //}
  //.grid-item-2{
  //    grid-column-start: 1;
  //    grid-row-start: 2;
  //}
  //.grid-item-3{
  //    grid-column-start: 2;
  //}
  //.grid-item-4{
  //    grid-column-start: 3;
  //    grid-row-start: 2;
  //}
  //.grid-item-5{
  //    grid-column-start: 2;
  //    grid-row-start: 2;
  //}
  //.grid-item-6{
  //    grid-column-start: 3;
  //    grid-row-start: 1;
  //}
.service-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(16.1vw - 1.2em);
  aspect-ratio: 1;
  z-index: 10;
  .dashed{
      width: 220px;
      //border: 2px dashed #000;
      border: 2px dashed #000;
      position: absolute;
      z-index: -100;
  }
  .dashed-left{
      right: -20%;
      top: 0%;
      rotate: -30deg;
  }
  .dashed-right{
      left: -20%;
      top: 0%;
      rotate: 30deg;
  }
  @include backgroundTransparency(#000, 1);
  h4{
    font-family: 'BC-Liguria', sans-serif;
    color: #8C9091 !important;
  }
  .service-box-info-container {
    margin: var(--main-space);
    display: flex;
    justify-content: center;
    align-items: center;
    figure {
      margin: 0;
      // margin-left: calc(-1 * var(--main-space));
      margin-top: calc(-1 * var(--main-space));
      height: 200px;
      margin-bottom: var(--main-space);
      img {
        width: 100%;
        @supports (object-fit: cover) {
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }
    }
    h4 {
      font-size: 24px;
    }
  }

  &.height-auto {
    height: auto;
    .service-box-info-container {
      height: auto;
      p {
        -webkit-line-clamp: inherit;
      }
    }
  }
  &.img-lg {
    .service-box-info-container {
      figure {
        height: 400px;
      }
    }
  }
  &.img-md {
    .service-box-info-container {
      figure {
        height: 300px;
      }
    }
  }

  &.benefit-box-stacbond {
    h4 {
      text-align: center;
      font-size: 18px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .grid-item-2{
      grid-column-start: 2;
      grid-row-start: 2;
      border-radius: 200px;
  }
  .grid-item-3{
      grid-column-start: 3;
  }
  .grid-item-4{
      grid-column-start: 4;
      grid-row-start: 2;
      border-radius: 200px;
  }
  .grid-item-5{
      grid-column-start: 7;
      grid-row-start: 1;
  }
  .grid-item-6{
      grid-column-start: 6;
      grid-row-start: 1;
  }
}
@media (min-width: 2000px){
  .service-box{
    width: calc(14.6vw + 0.6vw);
  }
}
@media (max-width: 1500px){
  .service-box{
    width: calc(14.7vw - 0.1em);
  }
  .dashed{
      width: 270px;
      //border: 2px dashed #000;
      border: 2px dashed #000;
      position: absolute;
      z-index: -100;
      .dashed-left{
        right: -10%;
        top: 0%;
        rotate: -30deg;
      .dashed-right{
        left: -10%;
        top: 0%;
        rotate: 30deg;
      }
    }
  }
}
@media (max-width: 1380px){
  .service-box{
    width: calc(14.7vw - 0.2em);
  }
  .dashed{
     width: 200px;
     //border: 2px dashed #000;
     border: 2px dashed #000;
     position: absolute;
     z-index: -100;
   }
}
@media (max-width: 1240px){
   .service-box{
     width: calc(14.7vw - 0.3em);
   }
   .dashed{
      width: 150px !important;
      //border: 2px dashed #000;
      border: 2px dashed #000;
      position: absolute;
      z-index: -100;
      .dashed-left{
        right: -10%;
        top: 0%;
        rotate: -60deg;
      .dashed-right{
        left: -10%;
        top: 0%;
        rotate: 60deg;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .service-box {
    width: calc(14.7vw - 0.4em);
      .dashed-left{
          right: -40%;
          top: 0%;
          rotate: -30deg;
      }
      .dashed-right{
          left: -40%;
          top: 0%;
          rotate: 30deg;
      }
    .service-box-info-container {
      p {
        -webkit-line-clamp: 3;
      }
    }
    &.height-auto {
      height: auto;
      .service-box-info-container {
        height: auto;
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }
  }
}
@media (max-width: 1024px){
  .service-box{
     width: calc(14.7vw - 0.6em);
   }
}
@media (max-width: 992px){
  .service-box{
     width: calc(46vw - 0.6em);
   }
  .dashed{
     display: none;
  }
  .grid-item-1{
      grid-column-start: 1;
      grid-row-start: 1;
  }
  .grid-item-2{
      grid-column-start: 2;
      grid-row-start: 1;
      border-radius: none;
  }
  .grid-item-3{
      grid-column-start: 1;
      grid-row-start: 2;
  }
  .grid-item-4{
      grid-column-start: 2;
      grid-row-start: 2;
      border-radius: none;
  }
  .grid-item-5{
      grid-column-start: 1;
      grid-row-start: 3;
  }
  .grid-item-6{
      grid-column-start: 2;
      grid-row-start: 3;
  }
}

@include media-breakpoint-down(md) {
  .service-box {
     width: 46vw;
    .service-box-info-container {
      p {
        -webkit-line-clamp: 4;
      }
    }
    &.md-height-auto {
      height: auto;
      .service-box-info-container {
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }
    &.height-auto {
      height: auto;
      .service-box-info-container {
        height: auto;
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .service-box {
    &.min-lg-height {
      width: 50vw;
      min-height: 300px;
    }
    height: auto;
    .service-box-info-container {
      p {
        -webkit-line-clamp: inherit;
      }
    }
    &.height-auto {
      height: auto;
      .service-box-info-container {
        height: auto;
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }
  }
  .grid-item-1{
      grid-column-start: 1;
      grid-row-start: 1;
  }
  .grid-item-2{
      grid-column-start: 1;
      grid-row-start: 2;
  }
  .grid-item-3{
      grid-column-start: 1;
      grid-row-start: 3;
  }
  .grid-item-4{
      grid-column-start: 1;
      grid-row-start: 4;
  }
  .grid-item-5{
      grid-column-start: 1;
      grid-row-start: 5;
  }
  .grid-item-6{
      grid-column-start: 1;
      grid-row-start: 6;
  }
}
</style>
