import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from 'gsap'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
import { EventBus } from '../../event-bus'
import Helper from '../../helpers/Helper'
// import '../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

var controller
export function fadeInOutSection () {
  let stopMobile = (window.innerWidth < 768)
  if (controller !== undefined) {
    controller.destroy()
  }
  controller = new ScrollMagic.Controller({
    globalSceneOptions: {
      triggerHook: 'onLeave'
    }
  })

  // VERIFICO QUE EXISTA ALGUN ELEMENTO CON LA CLASE, PARA EVITAR ERRORES
  if (document.getElementsByClassName('scrollAnimation1').length > 0) {
    var tween = new TimelineMax()
    tween.from('.scrollAnimation1', 0, { opacity: 1, yPercent: 0 })
    tween.to('.scrollAnimation1', 1, { opacity: 0, yPercent: -5 })

    new ScrollMagic.Scene({
      triggerElement: '.scrollAnimation1',
      offset: '10px',
      duration: '100%'
    })
      .setPin('.scrollAnimation1', { pushFollowers: false })
      .setTween(tween)
      .addTo(controller)
      .on('progress', (event) => {
        const showSliderElemens = !(event.progress > 0.5)
        EventBus.$emit('toggleSliderFirstElemns', showSliderElemens)
      })
  }

  if (document.getElementsByClassName('scrollAnimation2').length > 0) {
    var tween2 = new TimelineMax()
    tween2.from('.scrollAnimation2', 0, { opacity: 0 })
    tween2.to('.scrollAnimation2', 1, { opacity: 1 })

    new ScrollMagic.Scene({
      triggerElement: '.scrollAnimation2',
      offset: '-300px',
      duration: '45%'
    })
      .setTween(tween2)
      .addTo(controller)
      .on('progress', (event) => {
        if (Helper.isFundermax()) {
          const togglePDFFundermax = (event.progress > 0 && event.progress < 1)
          EventBus.$emit('togglePDFFundermax', togglePDFFundermax)
        }
        if (Helper.isStacBond()) {
          const togglePDFStacBond = (event.progress > 0 && event.progress < 1)
          EventBus.$emit('togglePDFStacBond', togglePDFStacBond)
        }
      })

    var tween3 = new TimelineMax()
    tween3.from('.scrollAnimation2', 0, { opacity: 1, yPercent: 0 })
    tween3.to('.scrollAnimation2', 1, { opacity: 0, yPercent: -5 })

    new ScrollMagic.Scene({
      triggerElement: '.scrollAnimation2',
      offset: '50%',
      duration: '50%'
    })
      .setPin('.scrollAnimation2', { pushFollowers: false })
      .setTween(tween3)
      .addTo(controller)
      .on('progress', (event) => {
        const showSliderElemens = !(event.progress > 0.5)
        EventBus.$emit('toggleSliderFinalElemns', showSliderElemens)
      })
  }
  const scrollAnimation3 = document.getElementsByClassName('scrollAnimation3')
  if (scrollAnimation3.length > 0) {
    var tween4 = new TimelineMax()
    tween4.from('.scrollAnimation3', 0, { opacity: 1, yPercent: 0 })
    tween4.to('.scrollAnimation3', 1, { opacity: 0, yPercent: -5 })

    const scene4 = new ScrollMagic.Scene({
      triggerElement: '.scrollAnimation3',
      // offset: (scrollAnimation3[0].clientHeight * 0.1) + 'px',
      offset: scrollAnimation3[0].clientHeight + 'px',
      duration: '100%'
    })
      .setPin('.scrollAnimation3', { pushFollowers: false })
      .setTween(tween4)
      .addTo(controller)
      .triggerHook('onEnter')

    scene4.enabled(!stopMobile)
    window.addEventListener('resize', (e) => {
      stopMobile = (e.target.innerWidth < 768)
      scene4.enabled(!stopMobile)
    })
  }
  if (document.getElementsByClassName('scrollAnimation4').length > 0) {
    const scene5 = new ScrollMagic.Scene({
      triggerElement: '.scrollAnimation4',
      offset: '-180px',
      duration: '100%'
    })
      .addTo(controller)
      .on('progress', (event) => {
      // if (Helper.isHome() || Helper.isFundermax()) {
      //   EventBus.$emit('toggleHeaderFamilyIcons', (event.progress === 0))
      // }
      })

    scene5.enabled(!stopMobile)
    window.addEventListener('resize', (e) => {
      stopMobile = (e.target.innerWidth < 768)
      scene5.enabled(!stopMobile)
    })
  }
}

export function scaleImg () {
  if (controller !== undefined) {
    controller.destroy()
  }
  controller = new ScrollMagic.Controller({
    globalSceneOptions: {
      triggerHook: 'onLeave'
    }
  })

  var tween = new TimelineMax()
  tween.from('.scrollImg', 0, { transform: 'scale(1.2)' })
  tween.to('.scrollImg', 1, { transform: 'scale(1)' })

  const elems = document.getElementsByClassName('scrollImg')
  elems.forEach(e => {
    new ScrollMagic.Scene({
      triggerElement: e,
      offset: 0,
      duration: '100%'
    })
      .setPin(e, { pushFollowers: false })
      .setTween(tween)
      .addTo(controller)
      .triggerHook('onEnter')
  })
}

export function overlayObra (options) {
  options = options || {}
  const isMobile = Helper.screenWidthLowerThan(768)
  if (isMobile) {
    const ctrl = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: (options.triggerHook || 'onEnter')
      }
    })

    const elems = document.getElementsByClassName('masonry-item-overlay')
    elems.forEach(e => {
      const tween = new TimelineMax()
      tween.from(e, 0.5, { opacity: 0 })
      tween.to(e, 1, { opacity: 1 })

      new ScrollMagic.Scene({
        triggerElement: e,
        offset: 0,
        duration: '100%'
      })
        .setTween(tween)
        .addTo(ctrl)
    })
  }
}

export function overlayFamilyProducts (options) {
  options = options || {}
  const isMobile = Helper.screenWidthLowerThan(768)
  if (isMobile) {
    const ctrl = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: (options.triggerHook || 'onEnter')
      }
    })

    const elems = document.getElementsByClassName('family-product-product')
    elems.forEach(e => {
      const o = e.getElementsByClassName('overlay')[0]
      const tween = new TimelineMax()
      tween.from(o, 0.5, { opacity: 0 })
      tween.to(o, 1, { opacity: 1 })

      new ScrollMagic.Scene({
        triggerElement: o,
        offset: 0,
        duration: '100%'
      })
        .setTween(tween)
        .addTo(ctrl)
    })
  }
}

export function overlayVideos (options) {
  options = options || {}
  const isMobile = Helper.screenWidthLowerThan(768)
  if (isMobile) {
    const ctrl = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: (options.triggerHook || 'onEnter')
      }
    })

    const elems = document.getElementsByClassName('video')
    elems.forEach(e => {
      const o = e.getElementsByClassName('video-grid-overlay')[0]
      const tween = new TimelineMax()
      tween.from(o, 0.5, { opacity: 0 })
      tween.to(o, 1, { opacity: 1 })

      new ScrollMagic.Scene({
        triggerElement: o,
        offset: 0,
        duration: '100%'
      })
        .setTween(tween)
        .addTo(ctrl)
    })
  }
}

export function backgroundOverlay (options) {
  options = options || {}
  // if (controller !== undefined) {
  //   controller.destroy()
  // }
  // controller = new ScrollMagic.Controller({
  //   globalSceneOptions: {
  //     triggerHook: 'onLeave'
  //   }
  // })

  // VERIFICO QUE EXISTA ALGUN ELEMENTO CON LA CLASE, PARA EVITAR ERRORES
  if (document.getElementsByClassName('scrollAnimationOverlay').length > 0) {
    var tween = new TimelineMax()
    // tween.from('.scrollAnimationOverlay', 0, { opacity: 1 })
    tween.to('.scrollAnimationOverlay', 1, { opacity: 1 })

    new ScrollMagic.Scene({
      triggerElement: '.scrollAnimationOverlayParent',
      offset: 0,
      duration: '300%'
    })
      // .setPin('.scrollAnimationOverlay', { pushFollowers: false })
      .setTween(tween)
      .addTo(controller)
  }
}
