<template>
    <article class="work-foto">
        <Loading v-if="showLoading" :inside-container="true" />
        <FadeUp
        :src="foto.foto"
         @load="imageLoaded()"
        :src-placeholder="require('@/assets/img/default-img.jpg')"/>
    </article>
</template>

<script>
import FadeUp from '@/components/lazy-load-images/FadeImage'
import Loading from '@/components/Loading'
export default {
  name: 'Work',
  props: ['foto'],
  components: {
    FadeUp,
    Loading
  },
  data () {
    return {
      showLoading: true
    }
  },
  computed: {

  },
  methods: {
    imageLoaded () {
      this.showLoading = false
    }
  }
}
</script>
<style scoped lang="scss">
    .work-foto{
      width: 100%;
      height: 500px;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
</style>
