<template>
  <div class="works-page">
    <div class="works-container" v-if="workSelected === null">
      <header class="works-header">
        <div class="d-flex flex-row align-items-center">
          <form action="#" class="works-header-form">
            <div class="select-container d-flex flex-row align-items-center" @click="toggleFiltroOptions('familia')">
              <span>Todas las familias</span>
              <Arrow/>
            </div>
            <div class="select-container d-flex flex-row align-items-center" @click="toggleFiltroOptions('productos')">
              <span>Todos los Productos</span>
              <Arrow/>
            </div>
            <div class="select-container d-flex flex-row align-items-center" @click="toggleFiltroOptions('aplicaciones')">
              <span>Todas las Aplicaciones</span>
              <Arrow/>
            </div>
            <div class="select-container d-flex flex-row align-items-center" @click="toggleFiltroOptions('materiales')">
              <span>Todas las Materialidades</span>
              <Arrow/>
            </div>
          </form>
        </div>
      </header>
      <div class="sub-header">
          <!-- OPCIONES DE FILTRO DE FAMILIAS-->
          <div class="d-flex flex-row filtro-options-container flex-wrap" v-if="filtrosOptions.familia">
            <div class="filtro-option" :class="{'rounded-full': roundedSelected.length === 0 }" @click="setFiltro('familia', '')">{{$t('projects.filters.all')}}</div>
            <div v-for="familyOption in familias" :key="familyOption.id" class="filtro-option" :class="{'rounded-full': roundedSelected[familyOption.id] == true}"
                @click="setFiltro('familia', familyOption.id)"
                 :style="{'backgroundColor': familyOption.color, color: 'black'}">{{familyOption.name_lang[$i18n.locale]}}</div>
          </div>
          <!-- OPCIONES DE FILTRO DE PRODUCTOS-->
          <div class="d-flex flex-row flex-wrap filtro-options-container" v-if="filtrosOptions.productos">
            <div class="filtro-option" :class="{'rounded-full': roundedSelected.length === 0 }" @click="setFiltro('producto', '')">{{$t('projects.filters.all')}}</div>
            <div v-for="productoOption in allProductos" :key="productoOption.id" class="filtro-option" :class="{'rounded-full': roundedSelected[productoOption.id] == true}"
                 @click="setFiltro('producto', productoOption.id)">{{productoOption.nombre_lang[$i18n.locale]}}</div>
          </div>

          <!-- OPCIONES DE FILTRO DE APLICACIONES-->
          <div class="d-flex flex-row flex-wrap filtro-options-container" v-if="filtrosOptions.aplicaciones">
            <div class="filtro-option" :class="{'rounded-full': roundedSelected.length === 0 }" @click="setFiltro('aplicacion', '')">{{$t('projects.filters.all')}}</div>
            <div v-for="aplicacionOption in aplicaciones" :key="aplicacionOption.id" class="filtro-option" :class="{'rounded-full': roundedSelected[aplicacionOption.id] == true}"
                 @click="setFiltro('aplicacion', aplicacionOption.id)">{{aplicacionOption.nombre_lang[$i18n.locale]}}</div>
          </div>

          <!-- OPCIONES DE FILTRO DE MATERIALES-->
          <div class="d-flex flex-row flex-wrap filtro-options-container" v-if="filtrosOptions.materiales">
            <div class="filtro-option" :class="{'rounded-full': roundedSelected.length === 0 }" @click="setFiltro('material', '')">{{$t('projects.filters.all')}}</div>
            <div v-for="materialOption in materiales" :key="materialOption.id" class="filtro-option" :class="{'rounded-full': roundedSelected[materialOption.id] == true}"
                 @click="setFiltro('material', materialOption.id)">{{materialOption.nombre_lang[$i18n.locale]}}</div>
          </div>
      </div>
      <div class="works">
        <DinamicGridLayout v-if="hayObrasFiltradas" :obras="obrasFiltradas" />
      </div>
    </div>
  </div>
</template>

<script>
import ObrasService from '../services/ObrasService'
import FamiliasProductosService from '@/services/FamiliasProductosService'
import DinamicGridLayout from '../components/DinamicGrid/Images.vue'
import Arrow from '../assets/img/updown-arrow-icon.svg'
import { EventBus } from '@/event-bus'
import { isMobile } from 'mobile-device-detect'
import Helper from '../helpers/Helper'
export default {
  name: 'Proyectos',
  props: {
    // familias: Array,
    filtroFamilia: String,
    filtroProducto: String,
    filtroAplicacion: String,
    filtroMaterial: String
  },
  components: {
    DinamicGridLayout,
    Arrow
  },
  data () {
    return {
      isMobile,

      familias: [],

      obras: [],
      obrasFiltradas: [],

      aplicaciones: [],
      productos: [],
      materiales: [],
      filtro: {
        familia: {
          id: ''
        },
        producto: {
          id: ''
        },
        aplicacion: {
          id: ''
        },
        material: {
          id: ''
        }
      },
      filtrosOptions: {
        familia: false,
        productos: false,
        aplicaciones: false,
        materiales: false
      },

      cantProcess: 1,
      processed: 0,

      workSelected: null,
      familySelectedFilter: null,
      filterFamilyOptions: [],
      roundedSelected: []
    }
  },
  computed: {
    // familiasProp: {
    //   get () { return this.familias },
    //   set (val) { this.$emit('update:value', val) }
    // },
    // familiaProductos () {
    //  let productos = []
    //  if (this.filtro.familia.id !== '') {
    //    const familia = this.familias.find(f => f.id === this.filtro.familia.id)
    //    if (familia !== undefined) {
    //      productos = familia.productos
    //    }
    //  }
    //  return productos
    // },
    allProductos () {
      let productos = []
      if (this.filtro.familia.id !== '') {
        const familia = this.familias.find(f => f.id === this.filtro.familia.id)
        if (familia !== undefined) {
          productos = familia.productos
          return productos
        }
      } else {
        this.familias.forEach(familia => {
          productos = productos.concat(familia.productos)
        })
        return productos
      }
      return null
    },
    hayObrasFiltradas () {
      return this.obrasFiltradas.length > 0
    }
    // filtroFamiliaSelected () {
    //   let familySelected = {
    //     id: '',
    //     name_lang: {
    //       es: this.$t('projects.filters.families.all'),
    //       en: this.$t('projects.filters.families.all')
    //     }
    //   }
    //   if (this.filtro.familia.id !== '') {
    //     const selected = this.familias.find(f => f.id === this.filtro.familia.id)
    //     if (selected !== undefined) { familySelected = selected }
    //   }
    //   return familySelected
    // },
    // filtroProductoSelected () {
    //   let productoSelected = {
    //     id: '',
    //     nombre_lang: {
    //       es: this.$t('projects.filters.products.all'),
    //       en: this.$t('projects.filters.products.all')
    //     }
    //   }
    //   const productos = this.familiaProductos
    //   if (this.filtro.producto.id !== '') {
    //     const selected = productos.find(p => p.id === this.filtro.producto.id)
    //     if (selected !== undefined) { productoSelected = selected }
    //   }
    //   return productoSelected
    // },
    // filtroAplicacionSelected () {
    //   let aplicacionSelected = {
    //     id: '',
    //     nombre_lang: {
    //       es: this.$t('projects.filters.aplications.all'),
    //       en: this.$t('projects.filters.aplications.all')
    //     }
    //   }
    //   if (this.filtro.aplicacion.id !== '') {
    //     const selected = this.aplicaciones.find(a => a.id === this.filtro.aplicacion.id)
    //     if (selected !== undefined) { aplicacionSelected = selected }
    //   }
    //   return aplicacionSelected
    // },
    // filtroMaterialSelected () {
    //   let materialSelected = {
    //     id: '',
    //     nombre_lang: {
    //       es: this.$t('projects.filters.materials.all'),
    //       en: this.$t('projects.filters.materials.all')
    //     }
    //   }
    //   if (this.filtro.material.id !== '') {
    //     const selected = this.materiales.find(m => m.id === this.filtro.material.id)
    //     if (selected !== undefined) { materialSelected = selected }
    //   }
    //   return materialSelected
    // }
  },
  methods: {
    selectWork (work) {},
    closeWork () {
      this.workSelected = null
    },
    getObras () {
      const service = new ObrasService()
      service.findAll({}, (response) => {
        this.obras = response
        this.getAplicacionesFromObras()
        this.getMaterialesFromObras()
        this.processed++
      })
    },
    getFamilias () {
      const service = new FamiliasProductosService()
      service.findAll((response) => {
        response.forEach(r => {
          this.familias.push(FamiliasProductosService.parse(r))
        })
      })
    },
    getAplicacionesFromObras () {
      const aplicaciones = []
      this.obras.forEach(o => {
        if (o.aplicaciones !== undefined) {
          o.aplicaciones.forEach(a => {
            aplicaciones.push(a)
          })
        }
      })

      this.aplicaciones = this.quitarDuplicados(aplicaciones)
    },
    getMaterialesFromObras () {
      const materiales = []
      this.obras.forEach(o => {
        if (o.materiales !== undefined) {
          o.materiales.forEach(m => {
            materiales.push(m)
          })
        }
      })
      this.materiales = this.quitarDuplicados(materiales)
    },
    toggleFiltroOptions (filtro) {
      const filtroOptionShow = this.getFiltroOptionShow()
      this.hideFiltroOptions()
      this.filtrosOptions[filtro] = filtroOptionShow !== filtro
    },
    getFiltroOptionShow () {
      let propShow = null
      for (const prop in this.filtrosOptions) {
        if (this.filtrosOptions[prop]) {
          propShow = prop
        }
      }
      return propShow
    },
    setFiltro (filtro, value) {
      const comparation = this.filterFamilyOptions.some(f => f === value)
      if (filtro && value && !comparation) {
        this.roundedSelected[value] = !this.roundedSelected[value]
        this.filterFamilyOptions.push(value)
      }
      if (value === '') {
        this.roundedSelected = []
      }
      if (filtro === 'familia' && value === '') {
        this.filtro.familia.id = ''
        this.filtro.producto.id = ''
      } else {
        this.filtro[filtro].id = value
      }
      this.filtrar()
      this.hideFiltroOptions()
    },
    hideFiltroOptions () {
      this.filtrosOptions.familia = false
      this.filtrosOptions.productos = false
      this.filtrosOptions.aplicaciones = false
      this.filtrosOptions.materiales = false
    },
    filtrar () {
      this.obrasFiltradas = this.obras
      this.obrasFiltradas = this.filtrarPorFamilia()
      this.obrasFiltradas = this.filtrarPorProducto()
      this.obrasFiltradas = this.filtrarPorAplicacion()
      this.obrasFiltradas = this.filtrarPorMaterial()
    },
    filtrarPorFamilia () {
      // return this.obrasFiltradas.filter(o => this.filtro.familia.id === '' || (o.productos !== undefined && o.productos.find(p => p.familia.id === this.filtro.familia.id)))
      return this.obrasFiltradas.filter(o => {
        return this.filtro.familia.id === '' || (o.productos !== undefined && o.productos.find(p => this.filterFamilyOptions.includes(p.familia.id)))
      })
    },
    filtrarPorProducto () {
      return this.obrasFiltradas.filter(o => {
        return this.filtro.producto.id === '' || (o.productos !== undefined && o.productos.find(p => this.filterFamilyOptions.includes(p.id)))
      })
    },
    filtrarPorAplicacion () {
      return this.obrasFiltradas.filter(o => {
        return this.filtro.aplicacion.id === '' || (o.aplicaciones !== undefined && o.aplicaciones.find(a => this.filterFamilyOptions.includes(a.id)))
      })
    },
    filtrarPorMaterial () {
      return this.obrasFiltradas.filter(o => {
        return this.filtro.material.id === '' || (o.materiales !== undefined && o.materiales.find(m => this.filterFamilyOptions.includes(m.id)))
      })
    },
    quitarDuplicados (array) {
      return array.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    },
    setFiltrosFromURL () {
      const urlValues = Helper.getUrlParamsValues()
      for (const prop in urlValues) {
        if (this.filtro[prop] !== undefined) {
          this.setFiltro(prop, urlValues[prop])
        }
      }
    },
    setFiltrosFromParams () {
      this.setFiltro('familia', (this.filtroFamilia || ''))
      this.setFiltro('producto', (this.filtroProducto || ''))
      this.setFiltro('aplicacion', (this.filtroAplicacion || ''))
      this.setFiltro('material', (this.filtroMaterial || ''))
    }
  },
  mounted () {
    // this.setFiltrosFromParams()
    this.getObras()
    this.getFamilias()
    EventBus.$emit('toggleLoading', true)
    // EventBus.$emit('showTitlePage', this.$t('projects.pageTitle'))

    // if (this.familiasProp.length > 0) {
    //   this.processed++
    // }
    if (this.$route.params.object !== undefined && this.$route.params.object.id !== '') {
      this.selectWork(this.$route.params.object)
    }
  },
  watch: {
    // familias: {
    //   deep: true,
    //   handler () {
    //     this.processed++
    //   }
    // },
    filtro: {
      deep: true,
      handler () {
        this.filtrar()
      }
    },
    processed: {
      handler () {
        if (this.processed >= this.cantProcess) {
          this.setFiltrosFromParams()
          this.filtrar()
          EventBus.$emit('toggleLoading', false)
        }
      }
    },
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('projects.pageTitle'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .works-page {
    background-color: #1A1A1A;
    //min-height: calc(100vh - calc(4 * var(--main-space)) - 52px);
    min-height: 100vh;
    // padding-top: 65px;
    padding-top: calc(var(--main-space) * 4);
    width: 100%;
  }
  .rounded-full{
    border-radius: 30rem;
  }
  .works-container {
    //background-color: #FFFFFF;

    .works-header {
      width: 100%;
      //background: #FFFFFF;
      padding-inline: var(--main-space);

      // .works-tit {
      //   margin: 0 3rem 0 0;
      // }

      .works-header-form {
        /*width: calc(100% - 150px);*/
        width: 100%;
        display: grid;
        margin-block: 0 var(--main-space);
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: calc(var(--main-space) / 2);

        .select-container {
          cursor: pointer;
          background: black;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-block: 1rem;
          padding-inline: 1rem;
          svg {
            width: calc(var(--main-space) / 5 * 4);
            margin-right: 1rem;
            fill: $footer-text;
          }
          select,
          span
          {
            font-size: 1.4rem;
            /*padding: 0.5rem 0;*/
            padding: 0;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: $footer-text;
            font-weight: 600;
            width: auto;
            line-height: 100%;
          }
        }
      }
    }

    .sub-header {
      .filtro-options-container {
        padding-inline: 40px;
        padding-block-end: 40px;
        grid-gap: 20px;
        .filtro-option {
          padding-block: 1rem;
          padding-inline: 1rem;
          background-color: black;
          /*margin-right: calc(var(--main-space) / 2);*/
          color: $footer-text;
          cursor: pointer;
        }
      }
    }

    .works {
      /*height: calc(100vh - 5rem - 81px);*/
      overflow-y: auto;
      overflow-x: hidden;
      padding-inline: var(--main-space);
    }
  }

  @include media-breakpoint-down(md) {
    .works-container {
      .works-header {
        .works-header-form {
          grid-template-columns: 1fr 1fr;
          .select-container {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .works-page {
      min-height: auto;
      margin-top: calc(3 * var(--main-space));
    }
    .works-container {
      .works-header {
        .works-tit {
          font-size: 1.4rem;
          margin: 0;
        }
      }
    }
  }
</style>
