import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueCarousel from 'vue-carousel'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/helpers/filters'
// import { VLazyImagePlugin } from 'v-lazy-image'
import inViewPort from './directives/inViewPort'
import VueAnalytics from 'vue-analytics'

Vue.use(BootstrapVue)
// Vue.use(VLazyImagePlugin)
Vue.directive('inViewPort', inViewPort)
Vue.use(VueAnalytics, {
  // id: 'UA-85210266-1',
  id: 'UA-108341109-1',
  router
})
Vue.use(VueCarousel)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
