<template>
  <section class="materials-full-height">
      <!--<transition-group name="fade-in-right-group" class="materiales-section" mode="out-in" tag="section"
                      :style="{ '--total': materiales.length }">
                      <MaterialGridItem v-for="(material, index) in materiales" :key="material.id" :material="material"
                        :style="{'--i': index}" @selectMaterial="selectMaterial"></MaterialGridItem>
    </transition-group>-->
    <div class="images-materials">
      <DinamicGridImages :materials="materiales" @selectMaterial="selectMaterial" />
    </div>
  </section>
</template>

<script>
import MaterialesService from '../services/MaterialesService'
// import MaterialGridItem from '../components/materials/MaterialGridItem';
import DinamicGridImages from '../components/DinamicGrid/Images'
import { EventBus } from '@/event-bus'

export default {
  name: 'Materials',
  components: {
    DinamicGridImages
  },
  data () {
    return {
      materiales: [],
      showMaterials: false,
      materialSelected: null,
      posMaterialSelected: 0,
      classHeight: 'materials-full-height'
    }
  },
  computed: {
    hayMateriales () {
      return this.materiales.length > 0
    },
    materialesToShow () {
      if (!this.showMaterials) { return [] }
      return this.materiales
    }
  },
  methods: {
    getMateriales () {
      EventBus.$emit('toggleLoading', true)
      const service = new MaterialesService()
      service.findAll({}, (response) => {
        response.forEach(r => {
          this.materiales.push(MaterialesService.parse(r))
        })
        this.showMaterials = true
        EventBus.$emit('toggleLoading', false)
      })
    },
    selectMaterial (material) {
      this.materialSelected = material
      this.materiales.forEach((m, index) => {
        if (m.id === material.id) {
          this.posMaterialSelected = index
        }
      })
    },
    nextMaterial () {
      this.posMaterialSelected++
      if (this.posMaterialSelected >= this.materiales.length) {
        this.posMaterialSelected = 0
      }
      const m = this.materiales[this.posMaterialSelected]
      this.selectMaterial(m)
    }
  },
  mounted () {
    this.getMateriales()
    EventBus.$emit('showTitlePage', 'Materiales')
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('materials.pageTitle'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .materials-full-height{
    background: #1A1A1A;
  }
  .images-materials{
    padding-inline: var(--main-space);
    padding-block-start: 8rem;
  }
  .materiales{
    margin: auto;
    padding-block-start: 8rem;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background: #1A1A1A;
    padding-inline: 40px;
  }
  @include media-breakpoint-down(md) {
     .materiales {
        padding-inline: 2rem;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
     }
   }
  //   .materiales-section {
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     /*grid-gap: 1px;*/
  //     grid-auto-flow: row dense;
  //     padding-top: calc(var(--main-space) * 4);
  //   }
  // }

  // @include media-breakpoint-down(sm) {
  //   .materiales {
  //     .materiales-section {
  //       grid-template-columns: repeat(2, 1fr);
  //     }
  //   }
  // }
  // @include media-breakpoint-down(xs) {
  //   .materiales {
  //     .materiales-section {
  //       grid-template-columns: repeat(1, 1fr);
  //     }
  //   }
  // }
</style>
