<template>
  <transition-group name="fade-in-right-group" mode="out-in" tag="div"
                    class="d-flex flex-row align-items-center family-icons-menu-container"
                    :style="{ '--total': familyProducts.length }">
    <b-list-group-item class="family-icon-menu"
                       :class="{'family-icon-menu-last': (index === familyProducts.length - 1)}"
                       v-for="(family, index) in familyProducts" :key="family.id" :style="{'--i': index}">
      <router-link class="text-nowrap"
                   :to="{name: 'family-product', params: {familyProduct: family.id + '-' + family.path, object: family}}"
                   :id="'header-family-icon-' + family.id" >
        <figure :class="{'icon-compressed': iconsCompressed}">
          <img :class="{selected: isFamilySelected(family), 'img-svg-white': whiteIcons}" :src="family.icon" alt="" />
        </figure>
        <b-tooltip custom-class="header-family-icon-tooltip" :target="'header-family-icon-' + family.id" triggers="hover"
                   placement="bottom">
          <span class="bcLiguria text-nowrap d-flex flex-column justify-content-end" :style="{color: family.color}">{{family.name_lang[$i18n.locale]}}</span>
        </b-tooltip>
      </router-link>
    </b-list-group-item>
  </transition-group>
</template>

<script>
export default {
  name: 'Families',
  props: ['familyProducts', 'familySelected', 'iconsCompressed', 'white-icons'],
  methods: {
    isFamilySelected (family) {
      return (this.familySelected !== null && this.familySelected.id === family.id)
    },
    familyTooltip (family) {
      return (this.$i18n.locale === 'en') ? family.name_en : family.name
    }
  }
}
</script>

<style scoped lang="scss">
  .family-icons-menu-container {
    &:hover .family-icon-menu {
      opacity: 0.5;
    }

    .family-icon-menu {
      padding: 0;
      margin: 0 calc(var(--main-space) / 5 * 2) 0 0;
      transition: all 0.2s ease-in-out;
      background-color: transparent;
      border: none;
      a {
        display: block;
        figure {
          width: var(--main-space);
          height: var(--main-space);
          margin: 0 !important;
          overflow: hidden;

          img {
            &.selected {
              filter: none !important;
            }

            &:hover {
              filter: none !important;
            }
          }

          &.icon-compressed {
            img {
              &:not(.selected) {
                transform: translateY(-80%);
                -webkit-transform: translateY(-80%);
                -moz-transform: translateY(-80%);
                transition: all 0.1s ease-in-out;
                -webkit-transition: all 0.1s ease-in-out;
                -moz-transition: all 0.1s ease-in-out;
              }
            }

            &:hover > img:not(.selected) {
              transform: translateY(0);
              -webkit-transform: translateY(0);
              -moz-transform: translateY(0);
            }
          }
        }
      }
      &.family-icon-menu-last {
        margin-right: calc(var(--main-space) / 5 * 4);
      }
      &:hover {
        opacity: 1;
      }
    }
  }

  //@include media-breakpoint-down(md) {
  //  .family-icon-menu {
  //    display: none;
  //  }
  //}
  @media (max-width:1090px){
    .family-icon-menu{
      display: none;
    }
  }
</style>
