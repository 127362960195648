<template>
  <div class="partners-page">
    <div class="wrapper">
      <div class="d-flex flex-column justify-content-center fullH scrollAnimation1 subtit">
        <p class="bcLiguria partners-text">{{$t('partners.text')}}</p>
      </div>
      <header class="partners-header works-header">
        <div class="wrapper d-flex flex-row align-items-center">
          <form action="#" class="partners-header-form works-header-form">
            <div class="select-container d-flex flex-row align-items-center" @click="toggleFiltroOptions('pais')">
              <Arrow></Arrow>
              <span>{{filtroPaisSelected.name_lang[$i18n.locale]}}</span>
            </div>
            <div class="select-container d-flex flex-row align-items-center" v-if="filtro.pais.id === 1" @click="toggleFiltroOptions('provincia')">
              <Arrow></Arrow>
              <span>{{filtroProvinciaSelected.name_lang[$i18n.locale]}}</span>
            </div>
          </form>
        </div>
      </header>
      <div class="sub-header">
        <div class="wrapper">
          <!-- OPCIONES DE FILTRO DE PAISES-->
          <div class="d-flex flex-row filtro-options-container flex-wrap" v-if="filtrosOptions.pais">
            <div class="filtro-option" @click="setFiltro('pais', '')">{{$t('partners.filters.all')}}</div>
            <div v-for="paisOption in paises" :key="paisOption.id" class="filtro-option" @click="setFiltro('pais', paisOption.id)"
                 >{{paisOption.name_lang[$i18n.locale]}}</div>
          </div>
<!--           OPCIONES DE FILTRO DE PROVINCIAS-->
          <div class="d-flex flex-row flex-wrap filtro-options-container" v-if="filtrosOptions.provincia && filtro.pais.id === 1">
            <div class="filtro-option" @click="setFiltro('provincia', '')">{{$t('projects.filters.all')}}</div>
            <div v-for="provinciaOption in provinciasToShow" :key="provinciaOption.id" class="filtro-option"
                 @click="setFiltro('provincia', provinciaOption.id)">{{provinciaOption.name_lang[$i18n.locale]}}</div>
          </div>
        </div>
      </div>
      <transition-group name="fade-in-right-group" mode="out-in" tag="div" :style="{ '--total': partners.length }"
                        class="fullrow people-container parterns-container d-flex flex-row flex-wrap mt-4">
        <PartnerBox v-for="(partner, iPartner) in partnersFiltrados" :key="'partner-' + iPartner" :style="{'--i': iPartner}" :partner="partner"></PartnerBox>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import PartnerBox from '@/components/PartnerBox'
import partners from '../assets/js/partners.json'
import countries from '../assets/js/countries.json'
import { fadeInOutSection } from '@/assets/js/animations'
import Arrow from '../assets/img/updown-arrow-icon.svg'
export default {
  name: 'Partners',
  components: {
    PartnerBox,
    Arrow
  },
  data () {
    return {
      showPartner: true,
      partners,
      partnersFiltrados: [],
      filtro: {
        pais: {
          id: ''
        },
        provincia: {
          id: ''
        }
      },
      filtrosOptions: {
        pais: false,
        provincia: false
      },
      paises: countries
    }
  },
  computed: {
    partnersToShow () {
      if (!this.showPartner) { return [] }
      return this.partners.filter(p => p.show)
    },
    provinciasToShow () {
      const paisSelected = this.filtroPaisSelected
      if (paisSelected) {
        return paisSelected.provincias.filter(p => this.partners.some(pf => pf.provincia?.id === p.id))
      } else {
        return []
      }
    },
    filtroPaisSelected () {
      let paisSelected = {
        id: '',
        name_lang: {
          es: this.$t('partners.filters.country.all'),
          en: this.$t('partners.filters.country.all')
        }
      }
      if (this.filtro.pais.id !== '') {
        const selected = this.paises.find(p => p.id === this.filtro.pais.id)
        if (selected !== undefined) { paisSelected = selected }
      }
      return paisSelected
    },
    filtroProvinciaSelected () {
      let provinciaSelected = {
        id: '',
        name_lang: {
          es: this.$t('partners.filters.province.all'),
          en: this.$t('partners.filters.province.all')
        }
      }
      const provincias = this.filtroPaisSelected.provincias
      if (this.filtro.provincia.id !== '') {
        const selected = provincias.find(p => p.id === this.filtro.provincia.id)
        if (selected !== undefined) { provinciaSelected = selected }
      }
      return provinciaSelected
    }
  },
  methods: {
    sortPartners () {
      const array = [...this.partners]
      array.forEach((p, i) => {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      })
      this.partners = array
    },
    toggleFiltroOptions (filtro) {
      const filtroOptionShow = this.getFiltroOptionShow()
      this.hideFiltroOptions()
      this.filtrosOptions[filtro] = filtroOptionShow !== filtro
    },
    getFiltroOptionShow () {
      let propShow = null
      for (const prop in this.filtrosOptions) {
        if (this.filtrosOptions[prop]) {
          propShow = prop
        }
      }
      return propShow
    },
    setFiltro (filtro, value) {
      if (filtro === 'pais' && value === '') {
        this.filtro.pais.id = ''
        this.filtro.provincia.id = ''
      } else {
        this.filtro[filtro].id = value
      }
      this.filtrar()
      this.hideFiltroOptions()
    },
    hideFiltroOptions () {
      this.filtrosOptions.pais = false
      this.filtrosOptions.provincia = false
    },
    filtrar () {
      this.partnersFiltrados = this.partners
      this.partnersFiltrados = this.filtrarPorPais()
      this.partnersFiltrados = this.filtrarPorProvincia()
    },
    filtrarPorPais () {
      return this.partnersFiltrados.filter(p => {
        return this.filtro.pais.id === '' || (p.pais !== undefined && p.pais.id === this.filtro.pais.id)
      })
    },
    filtrarPorProvincia () {
      return this.partnersFiltrados.filter(p => {
        return this.filtro.provincia.id === '' || (p.provincia !== undefined && p.provincia.id === this.filtro.provincia.id)
      })
    }
  },
  mounted () {
    // this.partners = this.$t('team.partners')
    // this.sortPartners()
    // this.showPartner = true
    this.partnersFiltrados = this.partners
    EventBus.$emit('showTitlePage', 'Partners')

    fadeInOutSection()
  }
}
</script>

<style scoped lang="scss">

.works-header {
  width: 100%;
  //background: #FFFFFF;
  padding: var(--main-space) 0;
  background-color: #000 !important;

  .works-tit {
    margin: 0 3rem 0 0;
  }

  .works-header-form {
    /*width: calc(100% - 150px);*/
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: calc(var(--main-space) / 2);

    .select-container {
      cursor: pointer;
      svg {
        width: calc(var(--main-space) / 5 * 4);
        margin-right: calc(var(--main-space) / 5 * 2);
        fill: $footer-text;
      }

      select,
      span
      {
        font-size: 1.4rem;
        /*padding: 0.5rem 0;*/
        padding: 0;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: $footer-text;
        font-weight: 600;
        width: auto;
        line-height: 100%;
      }
    }
  }
}

.sub-header {
  .filtro-options-container {
    padding: 0 0 var(--main-space) 0;
    grid-gap: calc(var(--main-space) / 5 * 2);
    .filtro-option {
      padding: calc(var(--main-space) / 5) calc(var(--main-space) / 2);
      background-color: $main-color;
      /*margin-right: calc(var(--main-space) / 2);*/
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.parterns-container {
  min-height: 50vh;
}

.partners-text{
  padding: 0 var(--main-space);
}

.wrapper {
  .subtit {
    width: 100%;
    p {
      color: $footer-text;
      width: 70%;
      font-size: 36px;
      line-height: calc(var(--main-space)/5*6);
      margin: 0;
    }
  }
}

@include media-breakpoint-down(lg) {
  .wrapper {
    .subtit {
      p {
        font-size: 30px;
        line-height: 150%;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .wrapper {
    .subtit {
      p {
        width: 80%;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .works-header {
    .works-header-form {
      grid-template-columns: 1fr;
    }
  }
  .wrapper {
    .subtit {
      p {
        width: 100%;
        font-size: 24px;
      }
    }
  }
}
</style>
