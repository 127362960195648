<template>
  <div class="about">
    <Loading v-if="showLoading"></Loading>
    <!--    <div class="background-container-mobile" id="background-container-mobile" v-if="isMobileOnly || isIOS"></div>-->
    <div class="video-container">
      <div :data-vimeo-id="idVideo" id="handstick" class="video-player-container" v-if="!isMobileOnly && !isIOS"></div>
      <!--<div class="about-overlay scrollAnimationOverlay" id="about-overlay"></div>-->
      <div class="wrapper">
        <section class="hero-slider">
          <div class="slider-content d-flex flex-column justify-content-center fullH scrollAnimation1">
            <vue-typer class="text-white bg-liguria" text="Shaping the future" :repeat="0" />
            <div class="d-flex flex-row">
              <vue-typer text="of " :repeat="0" :pre-type-delay="1500" @completed="showHomeTyperLast = true" />
              <vue-typer :text="['Architects', 'Designers', 'Engineers', 'Techincal Specialist']" v-if="showHomeTyperLast"
                        :type-delay="70" erase-style="clear" caret-animation='expand' />
            </div>
          </div>
          <article class="mision-container scrollAnimation2 animationText">
            <div class="d-flex align-items-end fullH">
              <p class="text-white bcLiguria">{{$t('home.text')}}</p>
            </div>
          </article>
          <div class="arrow-slider" v-if="!isMobile">
            <router-link :to="{name: 'Fundermax'}">
              <ArrowIcon v-if="sliderElemns.showArrow"></ArrowIcon>
            </router-link>
          </div>
          <div class="d-none d-md-block mouse-container" v-if="!isMobile">
            <MouseIcon v-if="sliderElemns.showMouse"></MouseIcon>
          </div>
        </section>
      </div>
      <!--      <div class="about-sections-container">
        <div class="wrapper">
          <section class="about-section about-values">
            <h1 class="title-section bg-liguria">{{$t('about.titleValues')}}</h1>
            <AboutSection :data="{columns: 2, items: $t('about.values')}" :home-service-classes="['img-lg']"></AboutSection>
          </section>
          <section class="about-section about-services mt-4">
            <h1 class="title-section bg-liguria">{{$t('about.titleServices')}}</h1>
            <AboutSection :data="{columns: 3, items: $t('about.services')}" :home-service-classes="['img-md']"></AboutSection>
          </section>
        </div>
      </div>
    -->
      <section class="about-sections-container scrollAnimation4">
          <AboutValues v-for="(values, id) in $t('about.values')" :key='id' :values="values" :id="id"/>
      </section>
      <section class="people">
        <div class="d-flex flex-column justify-content-center subtit">
          <p class="text-white">{{$t('team.text')}}</p>
        </div>
        <transition-group name="fade-in-right-group" mode="out-in" tag="div" :style="{ '--total': peopleToShow.length }"
                          class="fullrow people-container d-flex flex-row flex-wrap">
          <PersonBox v-for="(person, iPerson) in peopleToShow" :key="'person-' + iPerson" :style="{'--i': iPerson}" :person="person"></PersonBox>
        </transition-group>
      </section>
    </div>
  </div>
</template>

<script>
// import services from '../assets/js/services'
import PersonBox from '../components/PersonBox'
import { fadeInOutSection, backgroundOverlay } from '@/assets/js/animations'
// import aboutValues from '../assets/js/about-values'
import ArrowIcon from '../assets/img/arrow-icon.svg'
import MouseIcon from '../assets/img/mouse-icon.svg'
// import AboutSection from '../components/AboutSection'
import { isMobileOnly, isIOS, isMobile } from 'mobile-device-detect'
import AboutValues from '../components/AboutValues.vue'
import { VueTyper } from 'vue-typer'
import Player from '@vimeo/player'
import Loading from '../components/Loading'
import { EventBus } from '@/event-bus'
import Helper from '@/helpers/Helper'
export default {
  name: 'About',
  components: {
    Loading,
    ArrowIcon,
    MouseIcon,
    VueTyper,
    AboutValues,
    PersonBox
  },
  data () {
    return {
      isMobileOnly,
      isMobile,
      isIOS,
      // services: services,
      // aboutValues: aboutValues,
      idVideo: 450166134,
      vimeoPlayer: null,
      showLoading: true,
      showHomeTyperLast: false,
      styleOverlay: {
        backgroundColor: 'rgba(0,0,0,0)'
      },
      sliderElemns: {
        showArrow: true,
        showMouse: true,
        showSliderInfo: true,
        showOverlay: true,
        showFloatingButtons: true
      },
      showPeople: false,
      people: null
    }
  },
  computed: {
    peopleToShow () {
      if (!this.showPeople) { return [] }
      return this.people.filter(p => p.show)
    }
  },
  methods: {
    vimeoPlayerOnLoad () {
      this.vimeoPlayer = new Player('handstick', {
        id: this.idVideo,
        muted: true,
        controls: false,
        loop: true,
        responsive: true
      })

      this.vimeoPlayer.loadVideo(this.idVideo).then(() => {
        this.vimeoPlayer.play().then(() => {
          this.showLoading = false
        }).catch(() => {
          this.showLoading = false
        })
      })
    },
    sortPeople () {
      const array = [...this.people]
      array.forEach((p, i) => {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      })
      this.people = array
    }
  },
  mounted () {
    fadeInOutSection()
    backgroundOverlay()
    window.addEventListener('scroll', (e) => {
      const footerHeight = Helper.isBottomOfPage() ? (document.getElementsByClassName('footer')[0].clientHeight) : 0

      if (document.getElementById('handstick') !== null) {
        // document.getElementById('handstick').style.transform = 'translateY(-' + footerHeight + 'px)'
        // document.getElementById('handstick').style.top = '-' + footerHeight + 'px'
        document.getElementById('handstick').style.zIndex = Helper.isBottomOfPage() ? '-1' : 'auto'
      }
      if (document.getElementById('background-container-mobile') !== null) {
        document.getElementById('background-container-mobile').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
      if (document.getElementById('about-overlay') !== null) {
        document.getElementById('about-overlay').style.transform = 'translateY(-' + footerHeight + 'px)'
      }
    })

    if (!isMobileOnly && !isIOS) {
      this.vimeoPlayerOnLoad()
    } else {
      this.showLoading = false
    }

    EventBus.$emit('showTitlePage', this.$t('about.pageTitle'))

    EventBus.$on('toggleSliderFirstElemns', (toggle) => {
      this.sliderElemns.showMouse = toggle
      this.sliderElemns.showSliderInfo = toggle
      this.sliderElemns.showOverlay = toggle
    })
    EventBus.$on('toggleSliderFinalElemns', (toggle) => {
      this.sliderElemns.showArrow = toggle
      this.sliderElemns.showFloatingButtons = toggle
    })
    EventBus.$emit('headerFamilySelected', null)

    this.people = this.$t('team.people')
    this.sortPeople()
    this.showPeople = true

    EventBus.$emit('showTitlePage', 'Equipo')
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('about.pageTitle'))
        EventBus.$emit('showTitlePage', this.$t('team.pageTitle'))
        this.people = this.$t('team.people')
      }
    },
    showLoading: {
      handler () {
        if (!this.showLoading) {
          EventBus.$emit('toggleLoading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero-slider{
    position: relative;
  }

  .about-sections-container{
    position: relative;
    z-index: 10;
  }
  .about {
    .background-container-mobile {
      background-image: url('../assets/img/about-background.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
    .video-container {
      width: 100%;
      height: 100%;
      z-index: -2;
      overflow: hidden;

      .video-player-container {
        /*width: 100%;*/
        /*height: 100vh;*/
        position: fixed;
        top: 0;
        left: 0;

        height: 100%;
        /*width: 177.77777778vh; !* 100 * 16 / 9 *!*/
        /*min-height: 56.25vw; !* 100 * 9 / 16 *!*/
        width: calc(100vh * 16 / 9); /* 100 * 16 / 9 */
        min-width: 100%;
        min-height: calc(100vw * 9 / 16); /* 100 * 9 / 16 */

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .about-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0;
      }
    }

    .about-sections-container {

    }
    .about-description {
      font-size: 36px;
      width: 80%;
      line-height: calc(var(--main-space)/5*6);
    }

    .about-section {
      padding-top: var(--main-space);
      .title-section {
        color: #FFFFFF;
        margin-bottom: var(--main-space);
        height: 40px;
      }
    }
  }
  .wrapper {
    width: 100% !important;
    padding-inline: var(--main-space)!important;
    z-index: 2;
    position: relative;
  }

  .mision-container{
    width: calc(100% + var(--main-space) + var(--main-space));
    background: #1a1a1aab !important;
    padding-inline: var(--main-space);
    margin-left: calc(var(--main-space)*(-1));
    padding-bottom: var(--main-space);
  }
  .mision-container p {
    max-width: 1200px;
    font-size: 58px;
    line-height: 1.15;
    font-family: 'BC-Liguria', sans-serif;
  }

  .people {
    background: #000000;
    .subtit {
      width: 100%;
      position: relative;
      padding-inline: var(--main-space);
      bottom: 0;
      background: #000000;
      padding-block: 160px;
      z-index: 10;
      p {
        width: 100%;
        max-width: 1080px;
        color: #8C9091 !important;
        font-size: 58px;
        line-height: 67px;
        font-family: 'BC-Liguria', sans-serif;
      }
    }
  }
  .people-container{
    background: #000000 !important;
    position: relative;
    z-index: 1000;
  }
  @media screen and (min-width: 1240px) and (max-width: 1240px)
  and (min-height: 600px) and (max-height: 800px) {
    .background-container-mobile {
      background-position: top;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .about {
      .about-description {
        font-size: 30px;
        line-height: 150%;
      }
    .subtit {
      width: 100%;
      position: relative;
      bottom: 0;
      z-index: 10;
      p {
        color: #8C9091 !important;
        font-size: 3rem;
        line-height: 1;
      }
    }
    }
  }

  @include media-breakpoint-down(sm) {
    .about {
      .about-description {
        font-size: 16px;
        width: 90%;
      }
      .about-section {
        .title-section {
          font-size: 28px;
          height: auto;
        }
      }
    }
    .people {
      .subtit {
        p{
          font-size: 28px;
        }
      }
    }
    .mision-container {
      width: calc(100% + var(--main-space));
      padding-bottom: calc(var(--main-space) * 6);
    }
    .mision-container p {
      font-size: 28px;
      padding-right: calc(var(--main-space) * 2);
      line-height: calc(var(--main-space) / 3 * 4);
      margin: 0 !important;
    }
  }
</style>
