<template>
  <!--<div class="material-popup-container">
    <div class="d-flex flex-column position-relative material-popup-info-container justify-content-center" :style="{backgroundImage: 'url(' + material.foto_file + ')'}">
      <CloseIcon class="material-close-icon hoverable" @click="$emit('closeMaterial')"></CloseIcon>-->
      <!--<div class="wrapper">-->
        <!--<MouseIcon />-->
        <!--<h2 class="bcLiguria">{{material.nombre_lang[$i18n.locale]}}</h2>
        <p class="">{{material.descripcion_lang[$i18n.locale]}}</p>
        <router-link v-if="!isMobile" class="d-inline-flex flex-row button-obras" :to="{name: 'Works', params: {filtroMaterial: material.id}}">
          <span>{{$t('materials.seeProjectsButton', {material: material.nombre_lang[$i18n.locale]})}}</span>
        </router-link>-->
      <!--</div>-->
      <!--<DotsSlider :items="materiales" :pos-active="pos" @selectMaterialByPos="selectMaterialByPos" @next="$emit('next')"></DotsSlider>-->
    <!--</div>
    </div>
    -->
    <section class="section-material">
      <div class="section-material-front">
        <img :src="material.foto_file" :alt="material.nombre" />
        <div>
          <p>.PDF</p>
        </div>
        <MouseIcon />
      </div>
      <div class="section-material-obras">
        <DinamicGridImages :obras="material.obras" />
      </div>
    </section>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import MaterialesService from '../services/MaterialesService'
import { EventBus } from '@/event-bus'
import DinamicGridImages from '../components/DinamicGrid/Images'
import MouseIcon from '@/assets/img/mouse-icon.svg'
export default {
  name: 'Material',
  // props: ['material', 'pos', 'materiales'],
  components: {
    DinamicGridImages,
    MouseIcon
  },
  data () {
    return {
      isMobile,
      id: this.$route.params.material.split('-')[0],
      material: []
    }
  },
  computed: {
    // hayMaterialObras () {
    //   return this.material.obras !== null && this.material.obras.length > 0
    // }
  },
  methods: {
    // selectMaterialByPos (pos) {
    //   const m = this.materiales[pos]
    //   this.$emit('selectMaterial', m)
    // }
    getMaterialById () {
      const service = new MaterialesService()
      EventBus.$emit('toggleLoading', true)
      service.findAll({}, response => {
        this.material = response.find(m => m.id === this.id)
        EventBus.$emit('toggleLoading', false)
        EventBus.$emit('showTitlePage', this.material.nombre)
      })
    }
  },
  mounted () {
    this.getMaterialById()
  }
}
</script>

<style scoped lang="scss">
  .section-material{
    background: #1A1A1A;
    &-front{
      img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        position: relative;
      }
      div{
        position: absolute;
        bottom: 25px;
        left: 40px;
        p{
          background:#1A1A1A;
          padding-block: 0.4rem;
          padding-inline: 1.2rem;
          color: white;
          font-family: 'IBMPlexSans-SemiBold', sans-serif;
        }
      }
      svg {
          position: absolute;
          bottom: 40px;
          left: 50%;
          width: 40px;
          fill: white;
          #wheel {
            fill: white;
            animation: mouseLoop infinite 2s;
          }
        }
    }
    &-obras{
      padding-inline: 40px;
      padding-block-start: 40px;
    }
  }
  /*
  .material-popup-container {
    z-index: 99;
    position: relative;
    background-color: #000000;
    transition: all 0.5s ease-in-out;
    margin-bottom: 2rem;
    .material-popup-info-container {
      background-color: #000000;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;

      -webkit-animation: backgroundFadeIn 5s ;
      animation: backgroundFadeIn 5s ;
      .wrapper {
        width: 3%;
        position: absolute;
        fill: #FFFFFF;
        left: 50%;
        bottom: 10%;
        // h2 {
        //   margin: 0;
        //   font-size: 61px;
        //   color: #FFFFFF;
        // }
        // p {
        //   color: #FFFFFF;
        //   font-size: 30px;
        //   width: 90%;
        //   //line-height: 40px;
        //   margin: var(--main-space) 0;
        // }
        // .button-obras {
        //   span {
        //     background-color: #000000;
        //     color: #FFFFFF;
        //     padding: calc(var(--main-space) / 2);
        //   }
        // }
      }
    }

    .material-close-icon {
      position: absolute;
      top: var(--main-space);
      right: var(--main-space);
      width: var(--main-space);
      height: var(--main-space);
      fill: #FFFFFF;
    }
  }

  @include media-breakpoint-down(lg) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: calc(var(--main-space) / 2);
            font-size: 48px;
          }
          p {
            font-size: 24px;
            line-height: 150%;
            width: 100%;
            margin: calc(var(--main-space) / 2) 0;
          }
          .button-obras {
            span {
              padding: calc(var(--main-space) / 5 * 2);
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .material-popup-container {
      .material-popup-info-container {
        .wrapper {
          h2 {
            margin-top: var(--main-space);
          }
          p {
            width: 100%;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .material-popup-container {
      .material-popup-info-container {
        min-height: 100vh;
        height: auto;
        .wrapper {
          h2 {
            font-size: 36px;
            margin-top: 0;
          }
          p {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }*/
</style>
