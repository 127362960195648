<template>
  <div class="service-box position-relative" :class="[classes, {'service-box-img': img}]" @click="$emit('click')">
    <div class="service-box-info-container" :class="{'not-hover': notHover}">
      <figure v-if="img">
        <img :src="require('../' + img)" alt="">
      </figure>
      <h4 class="text-white" :class="{'bcLiguria': description}">{{name}}</h4>
      <p class="text-white" v-if="description" :class="{'full-description': showFullDescription}" @click="showFullDescription = true">
        {{description}}
      </p>
      <div class="arrow-container" v-if="linkTo">
        <ArrowIcon></ArrowIcon>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '../assets/img/arrow-icon.svg'
export default {
  name: 'HomeService',
  props: ['name', 'description', 'model', 'linkTo', 'classes', 'img', 'notHover'],
  components: {
    ArrowIcon
  },
  data () {
    return {
      showFullDescription: false
    }
  }
}
</script>

<style scoped lang="scss">
  .service-box {
    --h: 75vh;
    &.service-box-fundermax {
      --h: 80vh;
    }
    height: calc(calc(var(--h) - 38px - var(--main-space)) / 2);
    @include backgroundTransparency(#000000, 0.5);

    .service-box-info-container {
      width: calc(100% - calc(var(--main-space) * 2));
      height: calc(100% - calc(var(--main-space) * 2));
      margin: var(--main-space);

      figure {
        margin: 0;
        overflow: hidden;
        width: calc(100% + calc(var(--main-space) * 2));
        margin-left: calc(-1 * var(--main-space));
        margin-top: calc(-1 * var(--main-space));
        height: 200px;
        margin-bottom: var(--main-space);

        img {
          width: 100%;
          @supports (object-fit: cover) {
            object-fit: cover;
            object-position: center;
            height: 100%;
          }
        }
      }

      h4 {
        margin-bottom: calc(var(--main-space) / 5 * 3);
        font-size: 24px;
      }
      p {
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
          -webkit-line-clamp: inherit;
        }
      }
      &:not(.not-hover):hover {
        overflow-y: auto;
      }
      &.not-hover {
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }

    &.height-auto {
      height: auto;
      .service-box-info-container {
        height: auto;
        p {
          -webkit-line-clamp: inherit;
        }
      }
    }
    &.img-lg {
      .service-box-info-container {
        figure {
          height: 400px;
        }
      }
    }
    &.img-md {
      .service-box-info-container {
        figure {
          height: 300px;
        }
      }
    }

    &.benefit-box-stacbond {
      h4 {
        text-align: center;
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    //.service-box {
      //&.min-lg-height {
      //  min-height: 300px;
      //}
  }
  @include media-breakpoint-down(lg) {
    .service-box {
      height: calc(calc(80vh - 38px - var(--main-space)) / 3);
      .service-box-info-container {
        h4 {
          margin-bottom: calc(var(--main-space) / 2);
        }
        p {
          -webkit-line-clamp: 3;
        }
      }
      &.height-auto {
        height: auto;
        .service-box-info-container {
          height: auto;
          p {
            -webkit-line-clamp: inherit;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .service-box {
      height: calc(calc(85vh - 38px - var(--main-space)) / 3);
      .service-box-info-container {
        p {
          -webkit-line-clamp: 4;
        }
      }
      &.md-height-auto {
        height: auto;
        .service-box-info-container {
          p {
            -webkit-line-clamp: inherit;
          }
        }
      }
      &.height-auto {
        height: auto;
        .service-box-info-container {
          height: auto;
          p {
            -webkit-line-clamp: inherit;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm)
  {
    .service-box {
      height: auto;
      .service-box-info-container {
        p {
          -webkit-line-clamp: inherit;
        }
      }
      &.height-auto {
        height: auto;
        .service-box-info-container {
          height: auto;
          p {
            -webkit-line-clamp: inherit;
          }
        }
      }
    }
  }
</style>
