<template>
  <div class="di-grid-container" v-if="dis.length > 0">
    <DIGridItem v-for="(di, index) in dis" :key="'di-'+index" :di="di" :index="index" @diSelected="diSelected"></DIGridItem>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import DIGridItem from '@/components/desing-insight/DIGridItem'
import DesignInsightService from '@/services/DesignInsightService'

export default {
  name: 'DesignInsight',
  components: {
    DIGridItem
  },
  data () {
    return {
      dis: [],
      disAux: []
    }
  },
  methods: {
    diSelected (obj) {
      this.dis.forEach(d => {
        if (d.id !== obj.di.id) {
          d.selected = false
        }
      })
      obj.di.selected = !obj.di.selected
      if (obj.di.selected) {
        if ((obj.index + 1) % 3 === 0) {
          this.dis.splice((obj.index - 1), 0, this.dis.splice(obj.index, 1)[0])
        }
      } else {
        const indexTo = this.disAux.findIndex(d => d.id === obj.di.id)
        if (indexTo !== undefined) {
          this.dis.splice(indexTo, 0, this.dis.splice(obj.index, 1)[0])
        }
      }
      this.turnOfDisNotSelected()
    },
    turnOfDisNotSelected () {
      const indexSelected = this.dis.findIndex(d => d.selected)
      this.dis.forEach(d => {
        d.turnOff = (indexSelected !== -1 && !d.selected)
      })
    },
    getDesignInsights () {
      EventBus.$emit('toggleLoading', true)
      const service = new DesignInsightService()
      service.findAll({}, (response) => {
        response.forEach(r => {
          this.dis.push(DesignInsightService.parse(r))
        })
        this.disAux = [...this.dis]
        EventBus.$emit('toggleLoading', false)
      })
    }
  },
  mounted () {
    this.getDesignInsights()
    EventBus.$emit('showTitlePage', 'MSH Lab')
  }
}
</script>

<style scoped lang="scss">
  .di-grid-container {
    background-color: #8C9091;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
    padding-inline: var(--main-space);
    grid-auto-flow: row dense;
    min-height: 100vh;
    padding-top: calc(var(--main-space) * 4);
    padding-bottom: calc(var(--main-space) * 2);
    transition: all 0.5s ease-in-out;
  }
  @include media-breakpoint-down(sm) {
    .di-grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
