<template>
  <div class="">
    <div class="wrapper">
<!--      <h2 class="bcLiguria text-white tit">People</h2>-->
      <div class="d-flex flex-column justify-content-center fullH scrollAnimation1 subtit">
        <p class="bcLiguria">{{$t('team.text')}}</p>
      </div>
      <transition-group name="fade-in-right-group" mode="out-in" tag="div" :style="{ '--total': peopleToShow.length }"
                        class="fullrow people-container d-flex flex-row flex-wrap mt-4">
        <PersonBox v-for="(person, iPerson) in peopleToShow" :key="'person-' + iPerson" :style="{'--i': iPerson}" :person="person"></PersonBox>
      </transition-group>
    </div>
  </div>
</template>

<script>
import PersonBox from '../components/PersonBox'
import { EventBus } from '@/event-bus'
import { fadeInOutSection } from '@/assets/js/animations'

export default {
  name: 'People',
  components: {
    PersonBox
  },
  data () {
    return {
      showPeople: false,
      people: null
    }
  },
  computed: {
    peopleToShow () {
      if (!this.showPeople) { return [] }
      return this.people.filter(p => p.show)
    }
  },
  methods: {
    sortPeople () {
      const array = [...this.people]
      array.forEach((p, i) => {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      })
      this.people = array
    }
  },
  mounted () {
    this.people = this.$t('team.people')
    this.sortPeople()
    this.showPeople = true
    EventBus.$emit('showTitlePage', 'Equipo')

    fadeInOutSection()
  },
  watch: {
    '$i18n.locale': {
      handler () {
        EventBus.$emit('showTitlePage', this.$t('team.pageTitle'))
        this.people = this.$t('team.people')
      }
    }
  }
}
</script>

<style scoped lang="scss">
/*
  .wrapper {
    .tit {
      font-size: 3rem;
      margin: 0;
    }
    .subtit {
      width: 100%;
      p {
        color: $footer-text;
        width: 70%;
        font-size: 36px;
        line-height: calc(var(--main-space)/5*6);
        margin: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .wrapper {
      .subtit {
        p {
          font-size: 30px;
          line-height: 150%;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .wrapper {
      .subtit {
        p {
          width: 80%;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .wrapper {
      .tit {
        font-size: 32px;
      }
      .subtit {
        p {
          width: 100%;
          font-size: 24px;
        }
      }
    }
  }*/
</style>
