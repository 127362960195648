<template>
    <transition-group class="container-products" tag="section" name="fade-in-right-group" mode="out-in" :style="{'--total': familyProducts.lenght}">
      <ProductLayout v-for="products in familyProducts" :key="products.id" :products="products"/>
    </transition-group>
</template>

<script>
import ProductLayout from '../components/ProductLayout.vue'
import FamiliasProductosService from '@/services/FamiliasProductosService'
export default {
  name: 'products',
  components: {
    ProductLayout
  },
  data () {
    return {
      familyProducts: []
    }
  },
  computed: {},
  methods: {
    getFamilias () {
      const service = new FamiliasProductosService()
      service.findAll((response) => {
        response.forEach(r => {
          this.familyProducts.push(FamiliasProductosService.parse(r))
        })
      })
    }
  },
  mounted () {
    this.getFamilias()
  }
}
</script>

<style lang="scss" scoped>
  .container-products{
    position: relative;
    background: #1A1A1A;
    padding-block: 6rem 1rem;
    padding-inline-start: var(--main-space);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    padding-top: calc(var(--main-space) * 4);
  }
</style>
